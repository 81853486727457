import { Form } from "react-bootstrap";
import styled from "styled-components";

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;

  & .form-row:first-child {
    margin-bottom: 24px;

    & > div:first-child {
      width: 100%;
    }
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 1.5rem 0;
  justify-content: flex-end;

  button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;

    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    padding: 0 24px;
    gap: 14px;

    border: none;
    background: #4672c5;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
      0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    color: white;
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 1px;
    text-transform: uppercase;

    svg {
      fill: #fff;
    }

    &:disabled {
      background: #807e80;
      cursor: not-allowed;
    }
  }
`;

export const TabsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  min-height: max-content;

  .nav-tabs {
    display: flex;
    margin: 0;
    border: none;
    border-radius: 4px 4px 0px 0px;
    gap: 6px;

    a.nav-item {
      padding: 14px 40px;
      background: #ffffff;

      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      color: #4d4c4d;

      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 4px 4px 0px 0px;
      border: none;

      &.active {
        font-family: "Cera Pro Bold", sans-serif;
      }

      &:not(.active) {
        background: #f3f2f2;
      }
    }
  }

  .tab-content {
    height: 100%;
    width: 100%;
    background: #ffffff;

    .tab-pane.active {
      height: 100%;
      width: 100%;
      background: #ffffff;
      transition: none;
    }

    .card {
      display: flex;
      flex: 1;
      height: 100%;
      width: 100%;

      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14),
        0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
      border-radius: 0 0 4px 4px;
      border: none;

      .card-body {
        padding: 24px;

        .user-list {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }
    }
  }

  .timer-alert {
    position: absolute;
    margin-top: 5px;
    margin-left: 610px;
  }
`;

export const Container = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 24px;

  & > div:first-child {
    width: 100%;
  }
`;
