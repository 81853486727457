import React, { useEffect } from "react";
import { Form, Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles/";
import * as auth from "../../../../store/ducks/auth.duck";
import { connect, useDispatch } from "react-redux";
import "./style.scss";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Container } from "./style.js";
import { InputField } from "../../../../pages/admin/components/InputField/index.js";
import { ContainerSelect } from "./style.js";
import { InexApiService } from "../../../../services/InexApiService";
import Swal from "sweetalert2";

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 90%;
    max-width: fit-content;
    margin: auto;
  }

  .modal-content {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 1px 1px rgba(0, 0, 0, 0.14),
      0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    margin: 0;
  }

  .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;

    width: fit-content;
    height: 100%;
    margin: 0;
    outline: none;

    padding: 16px;

    &::-webkit-scrollbar {
      width: 8px;
      background-color: #fff;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background: #999;
      border-radius: 12px;
    }
  }
`;

const initialUserValues = {
  horarioEntrada: null,
  horarioAlmoco: null,
  horarioRetorno: null,
  horarioSaida: null,
  modeloRelatorio: null,
  clientDefault: null
};

function ModalUserSettings(props) {
  const api = new InexApiService();
  const dispatch = useDispatch();
  const { open, setOpen, result, requestUser } = props;
  const useStyles = makeStyles(() => ({
    modalBody: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center"
    },
    message: {
      fontSize: "1rem"
    },
    spinnerBorder: {
      width: "2rem",
      height: "2rem"
    }
  }));

  const [userInfoInitial, setUserInfoInitial] = React.useState(
    initialUserValues
  );
  const [userInfo, setUserInfo] = React.useState(initialUserValues);
  const [reportTemplateOptions, setReportTemplateOptions] = React.useState([]);
  const [clientDefaultOptions, setClientDefaultOptions] = React.useState([]);

  const poppupOptions = [
    {
      name: "offset",
      options: {
        offset: [-10, 0]
      }
    }
  ];

  const handleChangeReportTemplate = (e, select2Event) => {
    if (select2Event?.name === "ReportTemplate") {
      setUserInfo({
        ...userInfo,
        modeloRelatorio: e
      });
      return;
    }
  };

  const handleChangeClientDefault = (e, select2Event) => {
    if (select2Event?.name === "ClientDefault") {
      setUserInfo({
        ...userInfo,
        clientDefault: e
      });
      return;
    }
  };

  const loadUserInfos = async () => {
    if (open) {
      const reqBase = { method: "GET", url: `/user/profile-info` };
      try {
        const res = await api.makeHttpRequest({
          ...reqBase
        });
        let clients =
          res.clients?.length > 0
            ? res.clients.map(c => ({ value: c.idClient, label: c.name }))
            : [];
        setClientDefaultOptions(clients);
        const reqBaseReports = { method: "GET", url: `/reporttemplate/getall` };
        const resBaseReports = await api.makeHttpRequest({
          ...reqBaseReports
        });
        let reports =
          resBaseReports?.length > 0
            ? resBaseReports.map(r => ({
                value: r.idReportTemplate,
                label: r.type
              }))
            : [];
        setReportTemplateOptions(reports);
        const userInfoData = {
          horarioEntrada: res.startFirstTimer
            ? moment(res.startFirstTimer).toDate()
            : null,
          horarioAlmoco: res.endFirstTimer
            ? moment(res.endFirstTimer).toDate()
            : null,
          horarioRetorno: res.startSecondTimer
            ? moment(res.startSecondTimer).toDate()
            : null,
          horarioSaida: res.endSecondTimer
            ? moment(res.endSecondTimer).toDate()
            : null,
          modeloRelatorio: {
            value: res.reportTemplate
              ? res.reportTemplate.idReportTemplate
              : "",
            label: res.reportTemplate ? res.reportTemplate.type : ""
          },
          clientDefault: {
            value: res.clientDefault ? res.clientDefault.idClient : "",
            label: res.clientDefault ? res.clientDefault.name : ""
          }
        };
        setUserInfo(userInfoData);
        setUserInfoInitial(userInfoData);
      } catch (error) {}
    }
  };

  useEffect(() => {
    loadUserInfos();
  }, [open]);

  const submit = async e => {
    e.preventDefault();
    try {
      const today = moment().format("YYYY-MM-DD");
      const formattedStartTime = moment(userInfo.horarioEntrada).format(
        "HH:mm:ss"
      );
      const formattedEndTime = moment(userInfo.horarioAlmoco).format(
        "HH:mm:ss"
      );
      const formattedStartSecondTime = moment(userInfo.horarioRetorno).format(
        "HH:mm:ss"
      );
      const formattedEndSecondTime = moment(userInfo.horarioSaida).format(
        "HH:mm:ss"
      );
      const body = {
        startFirstTimer: userInfo.horarioEntrada
          ? `${today}T${formattedStartTime}`
          : null,
        endFirstTimer: userInfo.horarioAlmoco
          ? `${today}T${formattedEndTime}`
          : null,
        startSecondTimer: userInfo.horarioRetorno
          ? `${today}T${formattedStartSecondTime}`
          : null,
        endSecondTimer: userInfo.horarioSaida
          ? `${today}T${formattedEndSecondTime}`
          : null,
        idReportTemplate: userInfo.modeloRelatorio
          ? userInfo.modeloRelatorio.value
          : null,
        idClientDefault: userInfo.clientDefault
          ? userInfo.clientDefault.value
          : null
      };

      await api.makeHttpRequest({
        method: "PUT",
        url: `/user/update-profile-info`,
        data: body
      });

      await dispatch(requestUser());

      Swal.fire({
        title: "Sucesso!",
        text: "Perfil atualizado com sucesso.",
        icon: "success",
        confirmButtonText: "Ok"
      }).then(() => {
        setOpen(false);
        window.location.reload(true);
      });
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao editar profissional, tente novamente mais tarde.",
        "error"
      );
    }
  };

  return (
    <StyledModal
      show={open}
      onHide={() => setOpen(false)}
      size="lg"
      centered
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Modal.Body>
        <div className="container-modal">
          <span className="title">Preferências do Usuário</span>
          <hr style={{ border: "1px solid #cccccc", width: "100%" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%"
            }}
          >
            <div>
              <span className="subtitle">Timer composto</span>
            </div>
            <div>
              <span>
                Total de Horas:{" "}
                {Math.floor(
                  (moment(userInfo.horarioAlmoco).diff(
                    moment(userInfo.horarioEntrada),
                    "minutes"
                  ) +
                    moment(userInfo.horarioSaida).diff(
                      moment(userInfo.horarioRetorno),
                      "minutes"
                    )) /
                    60
                )
                  .toString()
                  .padStart(2, "0")}
                :
                {(
                  (moment(userInfo.horarioAlmoco).diff(
                    moment(userInfo.horarioEntrada),
                    "minutes"
                  ) +
                    moment(userInfo.horarioSaida).diff(
                      moment(userInfo.horarioRetorno),
                      "minutes"
                    )) %
                  60
                )
                  .toString()
                  .padStart(2, "0")}
              </span>
            </div>
          </div>
          <br />
          <Container inputWidth={97.5}>
            <div className="time">
              <label>Entrada</label>
              <DatePicker
                selected={userInfo.horarioEntrada}
                onChange={hour =>
                  setUserInfo({
                    ...userInfo,
                    horarioEntrada: hour
                  })
                }
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeIntervals={5} // TODO: voltar para 15 minutos
                timeFormat="HH:mm"
                timeCaption="Entrada"
                placeholderText="--:--"
                showPopperArrow={false}
                popperClassName="datetime-popper"
                popperModifiers={poppupOptions}
                popperPlacement="bottom"
                minTime={moment()
                  .startOf("day")
                  .toDate()}
                maxTime={
                  userInfo.horarioAlmoco ??
                  moment()
                    .endOf("day")
                    .toDate()
                }
              />
            </div>
            <div className="time">
              <label>Intervalo</label>
              <DatePicker
                selected={userInfo.horarioAlmoco}
                onChange={hour =>
                  setUserInfo({
                    ...userInfo,
                    horarioAlmoco: hour
                  })
                }
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeIntervals={5} // TODO: voltar para 15 minutos
                timeFormat="HH:mm"
                timeCaption="Intervalo"
                placeholderText="--:--"
                showPopperArrow={false}
                popperClassName="datetime-popper"
                popperModifiers={poppupOptions}
                popperPlacement="bottom"
                minTime={
                  userInfo.horarioEntrada ??
                  moment()
                    .startOf("day")
                    .toDate()
                }
                maxTime={
                  userInfo.horarioRetorno ??
                  moment()
                    .endOf("day")
                    .toDate()
                }
              />
            </div>
            <div className="time">
              <label>Retorno</label>
              <DatePicker
                selected={userInfo.horarioRetorno}
                onChange={hour =>
                  setUserInfo({
                    ...userInfo,
                    horarioRetorno: hour
                  })
                }
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeIntervals={5} // TODO: voltar para 15 minutos
                timeFormat="HH:mm"
                timeCaption="Retorno"
                placeholderText="--:--"
                showPopperArrow={false}
                popperClassName="datetime-popper"
                popperModifiers={poppupOptions}
                popperPlacement="bottom"
                minTime={
                  userInfo.horarioAlmoco ??
                  moment()
                    .startOf("day")
                    .toDate()
                }
                maxTime={
                  userInfo.horarioSaida ??
                  moment()
                    .endOf("day")
                    .toDate()
                }
              />
            </div>
            <div className="time">
              <label>Saída</label>
              <DatePicker
                selected={userInfo.horarioSaida}
                onChange={hour =>
                  setUserInfo({
                    ...userInfo,
                    horarioSaida: hour
                  })
                }
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeIntervals={5} // TODO: voltar para 15 minutos
                timeFormat="HH:mm"
                timeCaption="Saída"
                placeholderText="--:--"
                showPopperArrow={false}
                popperClassName="datetime-popper"
                popperModifiers={poppupOptions}
                popperPlacement="bottom"
                minTime={
                  userInfo.horarioRetorno ??
                  moment()
                    .startOf("day")
                    .toDate()
                }
                maxTime={moment()
                  .endOf("day")
                  .toDate()}
              />
            </div>
          </Container>
          <ContainerSelect>
            <div className="reportTemplateContainer" style={{ width: "100%" }}>
              <Form.Group
                lg="5"
                xs="10"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <InputField
                  as="select2"
                  name="ReportTemplate"
                  label="Modelos de Relatório"
                  value={userInfo.modeloRelatorio}
                  onChange={handleChangeReportTemplate}
                  placeholder="Selecione o relatório"
                  options={reportTemplateOptions}
                  closeMenuOnSelect={true}
                />
              </Form.Group>
            </div>
            <div className="clientDefaultContainer" style={{ width: "100%" }}>
              <Form.Group
                lg="5"
                xs="10"
                style={{ paddingLeft: "0px", paddingRight: "0px" }}
              >
                <InputField
                  as="select2"
                  name="ClientDefault"
                  label="Cliente Principal"
                  value={userInfo.clientDefault}
                  onChange={handleChangeClientDefault}
                  placeholder="Selecione o cliente"
                  options={clientDefaultOptions}
                  closeMenuOnSelect={true}
                />
              </Form.Group>
            </div>
          </ContainerSelect>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
              width: "100%"
            }}
          >
            <button
              type="button"
              className="cancel"
              onClick={() => setOpen(false)}
            >
              CANCELAR
            </button>
            <button
              type="button"
              className="submit primary"
              onClick={submit}
              disabled={
                userInfo.clientDefault?.value ===
                  userInfoInitial.clientDefault?.value &&
                userInfo.modeloRelatorio?.value ===
                  userInfoInitial.modeloRelatorio?.value &&
                moment(userInfo.horarioEntrada).isSame(
                  moment(userInfoInitial.horarioEntrada)
                ) &&
                moment(userInfo.horarioAlmoco).isSame(
                  moment(userInfoInitial.horarioAlmoco)
                ) &&
                moment(userInfo.horarioRetorno).isSame(
                  moment(userInfoInitial.horarioRetorno)
                ) &&
                moment(userInfo.horarioSaida).isSame(
                  moment(userInfoInitial.horarioSaida)
                )
              }
            >
              SALVAR
            </button>
          </div>
        </div>
      </Modal.Body>
    </StyledModal>
  );
}

export default injectIntl(connect(null, auth.actions)(ModalUserSettings));
