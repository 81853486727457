import React, { useEffect, useState } from "react";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { WhatsappIcon } from "../../../../components/IconsSVG";
import { Card, Form, Col, Tabs, Tab } from "react-bootstrap";
import {
  StyledForm,
  MessageWhatsappCardBody,
  Button,
  DivButton,
  CycleData
} from "./styles";
import { InputField } from "../../components/InputField";
import { InexApiService } from "../../../../services/InexApiService";
import store from "../../../../store/store";
import moment from "moment";
import feriados from "../../../../assets/files/feriados2024.json";
import Swal from "sweetalert2";
import { getPortoDefaultDate } from "./../../../../utils/GetPortoDefaultDate";
import "moment/locale/pt-br";

const AddMessageWhatsapp = () => {
  const { auth } = store.getState();
  const api = new InexApiService();
  const [loading, setLoading] = useState(false);
  const [messageSelect, setMessageSelect] = useState();
  const [operationUser, setOperationUser] = useState();
  const [lastWorkDayOfCycle, setLastWorkDayOfCycle] = useState();
  const [closingDateToRegisterTimers, setDeadlineToRegisterTimers] = useState();
  const [emailsProfissionais, setEmailsProfissionais] = useState();
  const [beforeMessageEndTime, setBeforeMessageEndTime] = useState("");
  const [messageEndTime, setMessageEndTime] = useState("");
  const [afterMessageEndTime, setAfterMessageEndTime] = useState("");
  const [messageWeekly, setMessageWeekly] = useState("");
  const [messageBlank, setMessageBlank] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedClient, setSelectedClient] = useState({});
  const [cycleDate, setCycleDate] = useState(null);
  const [closingDate, setClosingDate] = useState(null);
  const [message, setMessage] = useState("");

  const getClientList = async () => {
    const response = await api.makeHttpRequest({
      url: "client/paged"
    });

    if (!!response) {
      setClientOptions(
        response.data.map(client => {
          return client;
        })
      );

      setSelectedClient(
        response.data.find(client => {
          return client.idClient === auth.user.clientDefault.idClient;
        })
      );
    }
  };

  const getClosingDate = async () => {
    if (!selectedClient.idClient || !cycleDate) return;

    const response = await api.makeHttpRequest({
      url: `whatsappmessage/closing-date?idClient=${
        selectedClient.idClient
      }&endDate=${moment(cycleDate.endDate).format("YYYY-MM-DD")}`
    });

    if (response)
      return setClosingDate(
        selectedClient.idClient === 2
          ? moment(response.date).set("date", 15)
          : response.endDate
      );

    setClosingDate(null);
  };

  const handleSubmitForm = async () => {
    if (message.includes("%PROFISSIONAL%")) {
      setLoading(true);
      const reqBase = { method: "POST", url: "/whatsappmessage/save" };

      const messageType =
        messageSelect === "before"
          ? 1
          : messageSelect === "end"
          ? 2
          : messageSelect === "after"
          ? 3
          : messageSelect === "weekly"
          ? 4
          : 5;

      let isNonWorkingDays = true;
      let lastWorkDay = moment(cycleDate.endDate).startOf("day");
      while (isNonWorkingDays) {
        let currentWeek = lastWorkDay.weekday();

        if (currentWeek === 6) {
          // É Sabado
          lastWorkDay.subtract(1, "day");
        } else if (currentWeek === 0) {
          // É Domingo
          lastWorkDay.subtract(2, "day");
        } else {
          let feriado = feriados.find(
            feriado => feriado.date === lastWorkDay.format("YYYY-MM-DD")
          );

          if (feriado) {
            lastWorkDay.subtract(1, "day");
          } else {
            isNonWorkingDays = false;
          }
        }
      }
      const data = {
        idUser: operationUser,
        messageType: messageType,
        lastWorkDayOfCycle: lastWorkDay.toDate(),
        deadlineToRegisterTimers: moment(closingDate).format("YYYY-MM-DD"),
        consultantInexEmails: emailsProfissionais?.replaceAll(" ", ""),
        message: message,
        idClient: selectedClient.idClient
      };

      try {
        const response = await api.makeHttpRequest({
          ...reqBase,
          data
        });

        if (!!response) {
          setEmailsProfissionais("");
          Swal.fire({
            title: "Sucesso!",
            text: response,
            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Ver lotes",
            cancelButtonText: "Fechar"
          }).then(result => {
            if (result.value) {
              window.location = "/admin/mensagem-whatsapp";
            }
          });

          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
        Swal.fire(
          "Erro!",
          e?.response?.data ? e.response.data : "Erro ao criar mensagem!",
          "error"
        );
      }
    } else {
      setLoading(false);
      Swal.fire({
        title: "Erro!",
        text: "%PROFISSIONAL% ausente nas mensagens!",
        icon: "error"
      });
    }
  };

  const changeEmails = value => {
    setEmailsProfissionais(value);
  };

  const onBlurEmails = value => {
    const newValue = value
      .replaceAll("\n", "")
      .replace(/\s+/g, "")
      .split(",");
    setEmailsProfissionais(newValue.join(", "));
  };

  const handleMessageSelectChange = event => {
    setMessageSelect(event.target.value);
  };

  useEffect(() => {
    window.setPageTitle("Timer - Admin");
  }, []);

  const handleClientChange = idClient => {
    setSelectedClient(
      clientOptions.find(client => {
        return client.idClient === Number(idClient);
      })
    );
  };

  const handleCycleDate = () => {
    setCycleDate(getPortoDefaultDate(selectedClient.initialCycleDay));
  };

  const handleCycleMonth = () => {
    if (!cycleDate) return "";
    const month = moment(cycleDate.endDate)
      .locale("pt-br")
      .format("MMMM");

    return month.charAt(0).toUpperCase() + month.slice(1);
  };

  const handleClosingDate = () => {
    if (!closingDate)
      return "data de fechamento não cadastrada para ciclo atual";
    const date = moment(closingDate).format("DD/MM/YYYY");

    return date;
  };

  const handleMessageType = messageType => {
    const now = moment();
    const startDate = moment(cycleDate?.startDate) || "";
    const endDate = moment(cycleDate?.endDate) || "";
    const messageClosingDate = moment(closingDate) || " ";
    const startWeekly = now
      .clone()
      .startOf("isoWeek")
      .subtract(1, "day");
    const endWeekly = now
      .clone()
      .endOf("isoWeek")
      .subtract(1, "day");

    if (messageType === "before") {
      setMessage(
        `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n` +
          `Passando para lembrar que dia ${messageClosingDate.format(
            "DD"
          )}/${messageClosingDate
            .format("MMMM")
            .charAt(0)
            .toUpperCase() +
            messageClosingDate
              .format("MMMM")
              .slice(1)} (${messageClosingDate.format(
            "dddd"
          )}) é o último dia para a conclusão do lançamento de horas desse ciclo que termina ${endDate.format(
            "DD"
          )}/${endDate
            .format("MMMM")
            .charAt(0)
            .toUpperCase() + endDate.format("MMMM").slice(1)}.\n` +
          `Importante lembrar de lançar as horas provisionadas de ${startDate.format(
            "DD"
          )}/${startDate
            .format("MMMM")
            .charAt(0)
            .toUpperCase() +
            startDate.format("MMMM").slice(1)} até ${endDate.format(
            "DD"
          )}/${endDate
            .format("MMMM")
            .charAt(0)
            .toUpperCase() + endDate.format("MMMM").slice(1)}.\n` +
          `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n` +
          `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`
      );
    } else if (messageType === "end") {
      setMessage(
        `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n` +
          `Passando para lembrar que hoje (${messageClosingDate.format(
            "DD"
          )}/${endDate
            .format("MMMM")
            .charAt(0)
            .toUpperCase() +
            messageClosingDate
              .format("MMMM")
              .slice(
                1
              )}) é o ultimo dia para a conclusão do lançamento de horas desse ciclo que termina no dia ${messageClosingDate.format(
            "DD"
          )}/${endDate
            .format("MMMM")
            .charAt(0)
            .toUpperCase() + endDate.format("MMMM").slice(1)}.\n` +
          `Verifiquei no sistema que ainda existem horas pendentes a serem carregadas. Não deixe para ultima hora.\n` +
          `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n` +
          `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`
      );
    } else if (messageType === "after") {
      setMessage(
        `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n` +
          `Passando para avisar que a data limite para o lançamento já passou, verifiquei no sistema que ainda existem horas pendentes a serem carregadas.\n` +
          `Poderia por gentileza efetuar o lançamento o mais breve possivel?\n` +
          `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n` +
          `Capriche e conte conosco. (não responder essa mensagem , qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`
      );
    } else if (messageType === "weekly") {
      setMessage(
        `Olá %PROFISSIONAL%, Sou a Margareth, assistente virtual da equipe de Operações da INEX.\n` +
          `Passando para lembrar que hoje ${now.format("DD")}/${now
            .format("MMMM")
            .charAt(0)
            .toUpperCase() + now.format("MMMM").slice(1)} (${now.format(
            "dddd"
          )}) é o dia para a conclusão do lançamento de horas da semana (de ${startWeekly.format(
            "DD"
          )} de ${startWeekly
            .format("MMMM")
            .charAt(0)
            .toUpperCase() +
            startWeekly.format("MMMM").slice(1)} à ${endWeekly.format(
            "DD"
          )} de ${endWeekly
            .format("MMMM")
            .charAt(0)
            .toUpperCase() + endWeekly.format("MMMM").slice(1)})\n` +
          `Contamos muito com a sua ajuda para que tenhamos todas as horas lançadas e assim possamos efetuar o fechamento com sucesso.\n` +
          `Capriche e conte conosco. (não responder essa mensagem, qualquer dúvida procure Jessica ou Yara da equipe de Operações INEX`
      );
    } else {
      setMessage("Olá %PROFISSIONAL%,");
    }
  };

  useEffect(() => {
    getClientList();
  }, []);

  useEffect(() => {
    handleCycleDate();
  }, [selectedClient]);

  useEffect(() => {
    getClosingDate();
    setMessageSelect("before");
  }, [cycleDate]);

  useEffect(() => {
    handleMessageType(messageSelect);
  }, [messageSelect, closingDate]);

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle title="WhatsApp" icon={WhatsappIcon} disableHeader />
        <Card className="mt-4">
          <MessageWhatsappCardBody>
            <StyledForm>
              <Form className="form-admin">
                <Form.Row>
                  {/* Lista de Clientes */}
                  <Form.Group as={Col} lg="4" xs="12">
                    <InputField
                      as="select"
                      name="cliente"
                      label="Cliente"
                      value={selectedClient.idClient}
                      onChange={e => handleClientChange(e.target.value)}
                      required
                    >
                      {clientOptions?.length > 0 &&
                        clientOptions.map(c => (
                          <option key={c.idClient} value={c.idClient}>
                            {c.name}
                          </option>
                        ))}
                    </InputField>
                  </Form.Group>
                  {/* Usuário */}
                  {/* Prazo para lançamento */}
                  <CycleData>
                    <p>
                      Ciclo atual: <strong>{handleCycleMonth()}</strong>
                    </p>
                    <p>
                      Último dia para lançamento:{" "}
                      <strong>{handleClosingDate()}</strong>
                    </p>
                  </CycleData>
                </Form.Row>
                <Form.Row>
                  {/* E-mails dos profissionais para o envio */}
                  <Form.Group as={Col} lg="12" xs="12">
                    <InputField
                      as="textarea"
                      name="emailsProfissionais"
                      label='E-mails - (Separe por ",")'
                      placeholder="E-mails dos profissionais para o envio."
                      value={emailsProfissionais}
                      onBlur={e => onBlurEmails(e.target.value)}
                      onChange={e => changeEmails(e.target.value)}
                      required
                    />
                  </Form.Group>
                </Form.Row>
                {/* Mensagens */}
                <Form.Row>
                  <Form.Group as={Col} lg="12" xs="12">
                    <input
                      type="radio"
                      checked={messageSelect === "before"}
                      value="before"
                      onChange={handleMessageSelectChange}
                    />{" "}
                    Antes do fim do prazo de lançamento
                    <br />
                    <input
                      type="radio"
                      checked={messageSelect === "end"}
                      value="end"
                      onChange={handleMessageSelectChange}
                    />{" "}
                    No ultimo dia do prazo de Lançamento
                    <br />
                    <input
                      type="radio"
                      checked={messageSelect === "after"}
                      value="after"
                      onChange={handleMessageSelectChange}
                    />{" "}
                    Após o ultimo dia de prazo de lançamento
                    <br />
                    <input
                      type="radio"
                      checked={messageSelect === "weekly"}
                      value="weekly"
                      onChange={handleMessageSelectChange}
                    />{" "}
                    Exclusivo para contratos semanais
                    <br />
                    <input
                      type="radio"
                      checked={messageSelect === "blank"}
                      value="blank"
                      onChange={handleMessageSelectChange}
                    />{" "}
                    Em branco
                    <br />
                  </Form.Group>
                </Form.Row>
                {messageSelect === "before" && (
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="beforeMessageEndTime"
                        label="Mensagem"
                        style={{ height: "130px" }}
                        placeholder="Mensagem a ser enviada antes do fim do prazo de lançamento."
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                )}
                {messageSelect === "end" && (
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="messageEndTime"
                        label="Mensagem"
                        placeholder="Mensagem a ser enviada no ultimo dia do prazo de Lançamento."
                        value={message}
                        style={{ height: "130px" }}
                        onChange={e => setMessage(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                )}
                {messageSelect === "after" && (
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="afterMessageEndTime"
                        label="Mensagem"
                        placeholder="Mensagem a ser enviada após o ultimo dia de prazo de lançamento."
                        value={message}
                        style={{ height: "130px" }}
                        onChange={e => setMessage(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                )}
                {messageSelect === "weekly" && (
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="messageWeekly"
                        label="Mensagem"
                        style={{ height: "130px" }}
                        placeholder="Mensagem a ser enviada para profissionais com contrato semanal."
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                )}
                {messageSelect === "blank" && (
                  <Form.Row>
                    <Form.Group as={Col} lg="12" xs="12">
                      <InputField
                        as="textarea"
                        name="messageBlank"
                        label="Mensagem"
                        placeholder="Mensagem em branco para mensagens customizáveis."
                        value={message}
                        style={{ height: "130px" }}
                        onChange={e => setMessage(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </Form.Row>
                )}
                <DivButton style={{ width: "100%" }}>
                  <Button
                    variant="primary"
                    type="button"
                    color="#4672C5"
                    onClick={handleSubmitForm}
                    title={"Salvar"}
                    style={{ float: "right" }}
                  >
                    <span className="text-light">Salvar</span>
                  </Button>
                </DivButton>
              </Form>
            </StyledForm>
          </MessageWhatsappCardBody>
        </Card>
        <br />
      </div>
    </>
  );
};

export default AddMessageWhatsapp;
