import React, { useEffect, useState } from "react";
import Datatable from "../../../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../../../partials/datatable/styles.js";
import useGetWindowWidth from "../../../../../../utils/useGetWindowWidth";
import moment from "moment";
import { FiEye, FiTrash2, FiSend } from "react-icons/fi";
import { Actions, Icon } from "./styles.js";
import Swal from "sweetalert2";
import { InexApiService } from "../../../../../../services/InexApiService.js";
import { IconButton } from "@material-ui/core";

const MessageWhatsappDatatable = ({
  reload,
  setReload,
  setNotWorkProfessionalTotal,
  setWorkProfessionalTotal,
  setPendingProfessionalTotal,
  filterStatus,
  idBatch
}) => {
  const [whatsappMessages, setWhatsappMessages] = useState([]);
  const api = new InexApiService();
  const [loading, setLoading] = useState(false);

  const width = useGetWindowWidth();

  const headRows = [
    { label: "Profissional (Nome)" },
    { label: "Profissional (E-mail)" },
    { label: "Gerente de Operações   (Nome)" },
    { label: "Cliente" },
    { label: "Data de Solicitação" },
    { label: "Período" },
    { label: "Tipo" },
    { label: "Status" },
    { label: "Ação" }
  ];

  const handleMessageView = message => {
    return Swal.fire("Conteúdo da mensagem", message);
  };

  const handleDeleteMessage = async id => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        url: `/whatsappmessage/delete/${id}`,
        method: "POST"
      });
      setLoading(false);
      if (!!response) {
        Swal.fire({
          title: "Sucesso!",
          text: "Mensagem excluída com sucesso",
          icon: "success",
          confirmButtonText: "Ok"
        }).then(result => {
          !!result && setReload(true);
        });
      }
    } catch (e) {
      setLoading(false);
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao excluir mensagem!",
        "error"
      );
    }
  };

  const handleResendMessage = async id => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        url: `/whatsappmessage/resend/${id}`,
        method: "POST"
      });
      setLoading(false);
      if (!!response) {
        Swal.fire({
          title: "Sucesso!",
          text: "Mensagem reenviada com sucesso",
          icon: "success",
          confirmButtonText: "Ok"
        }).then(result => {
          !!result && setReload(true);
        });
      }
    } catch (e) {
      setLoading(false);
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao reenviar mensagem!",
        "error"
      );
    }
  };

  const deleteMessageModal = async id => {
    return Swal.fire({
      title: "Deseja excluir esta mensagem?",
      text: "A mensagem será excluida permanentemente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    }).then(async result => {
      if (result.value) {
        await handleDeleteMessage(id);
      }
    });
  };

  const resendMessageModal = async id => {
    return Swal.fire({
      title: "Deseja reenviar esta mensagem?",
      text: "A mensagem atual será sobreposta",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    }).then(async result => {
      if (result.value) {
        await handleResendMessage(id);
      }
    });
  };

  const handleMessageType = id => {
    switch (id) {
      case 1:
        return "Antes do fim do prazo de lançamento";
      case 2:
        return "No ultimo dia do prazo de Lançamento";
      case 3:
        return "Após o ultimo dia de prazo de lançamento";
      case 4:
        return "Exclusivo para contratos semanais";
      default:
        return "Em branco";
    }
  };

  const formatRow = r => {
    const crudActions = [];

    return (
      <>
        <StyledTableRow
          hover
          pointer={""}
          tabIndex={-1}
          key={r.idWhatsappMessage}
          onClick={() => null}
        >
          <StyledTableCell scope="row">{r.consultantInexName}</StyledTableCell>
          <StyledTableCell scope="row">{r.consultantInexEmail}</StyledTableCell>
          <StyledTableCell scope="row">{r.operationName}</StyledTableCell>
          <StyledTableCell scope="row">{r.client?.name || "-"}</StyledTableCell>
          <StyledTableCell scope="row">
            {moment(r.creationDate).format("DD/MM/YYYY HH:mm:ss")}
          </StyledTableCell>
          <StyledTableCell scope="row">
            {moment(r.lastWorkDayOfCycle).format("DD/MM/YYYY")}
          </StyledTableCell>
          <StyledTableCell scope="row">
            {handleMessageType(r.messageType.idMessageCategory)}
          </StyledTableCell>
          <StyledTableCell scope="row">{r.status}</StyledTableCell>
          <StyledTableCell scope="row">
            <Actions>
              <IconButton
                size="small"
                title="Ver mensagem"
                onClick={() => handleMessageView(r.message)}
              >
                <FiEye size={16} />
              </IconButton>
              <IconButton
                size="small"
                title="Excluir mensagem"
                onClick={() => deleteMessageModal(r.idWhatsappMessage)}
              >
                <FiTrash2 size={16} />
              </IconButton>
              <IconButton
                size="small"
                title="Reenviar mensagem"
                onClick={() => resendMessageModal(r.idWhatsappMessage)}
              >
                <FiSend size={16} />
              </IconButton>
            </Actions>
          </StyledTableCell>
        </StyledTableRow>
      </>
    );
  };

  useEffect(() => {
    setNotWorkProfessionalTotal(
      whatsappMessages.filter(
        wm =>
          wm.status !== "Não Enviada - Usuário em dia" &&
          wm.status !== "Pendente"
      ).length
    );
    setWorkProfessionalTotal(
      whatsappMessages.filter(
        wm => wm.status === "Não Enviada - Usuário em dia"
      ).length
    );
    setPendingProfessionalTotal(
      whatsappMessages.filter(wm => wm.status === "Pendente").length
    );
  }, [whatsappMessages]);

  return (
    <>
      <Datatable
        search={""}
        dateRange={""}
        endpoint={`whatsappmessage/paged`}
        className="h-100 m-0 p-0"
        headRows={headRows}
        styledTableCell={StyledTableCell}
        styledTableRow={StyledTableRow}
        formatRow={formatRow}
        rows={whatsappMessages}
        setRows={setWhatsappMessages}
        hiddenReload
        width={width}
        idBatch={idBatch}
        filterStatusWhatsappMessage={filterStatus}
        reload={reload}
        setReload={setReload}
      />
    </>
  );
};

export default MessageWhatsappDatatable;
