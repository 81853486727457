import styled from "styled-components";
import { Link } from "react-router-dom";

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

export const Th = styled.th`
  background-color: #f4f4f4;
  padding: 10px;
  text-align: left;
  border-bottom: 2px solid #ddd;
`;

export const Td = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

export const Tr = styled.tr``;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Button = styled(Link)`
  all: unset;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 2rem;
  background-color: #4672c5;
  color: white;
  border: none;
  padding: 0 0.5rem;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    color: white;
  }
`;

export const DeleteButton = styled.button`
  all: unset;
  height: 2rem;
  background-color: #4672c5;
  color: white;
  border: none;
  padding: 0 0.5rem;
  cursor: pointer;
  border-radius: 4px;
  margin-left: 0.5rem;
`;
