import React, { useEffect, useState } from "react";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { WhatsappIcon } from "../../../../components/IconsSVG";
import { Link } from "react-router-dom";
import { Card, Form, Col, Tabs, Tab } from "react-bootstrap";
import {
  StyledForm,
  MessageWhatsappCardBody,
  Button,
  DivButton
} from "./styles";
import { InputField } from "../../components/InputField";
import MessageWhatsappDatatable from "./components/MessageWhatsappDatatable";
import { InexApiService } from "../../../../services/InexApiService";
import store from "../../../../store/store";
import moment from "moment";
import { useParams } from "react-router-dom";

const BatchMessageWhatsapp = () => {
  const { id } = useParams();

  const api = new InexApiService();
  const [loading, setLoading] = useState(false);
  const [notWorkProfessionalTotal, setNotWorkProfessionalTotal] = useState(0);
  const [workProfessionalTotal, setWorkProfessionalTotal] = useState(0);
  const [pendingProfessionalTotal, setPendingProfessionalTotal] = useState(0);
  const [filterStatus, setFilterStatus] = useState("");
  const [reload, setReload] = useState(false);

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle title="WhatsApp" icon={WhatsappIcon} disableHeader />
        <Card className="mt-4">
          <MessageWhatsappCardBody>
            <StyledForm>
              <Form className="form-admin">
                <DivButton style={{ width: "100%" }}>
                  <div style={{ float: "left", alignItems: "center" }}>
                    Profissionais:
                    <br />
                    <a
                      style={{ color: "#4672C5" }}
                      onClick={() =>
                        setFilterStatus("Não Enviada - Usuário em dia")
                      }
                    >
                      Em dias com lançamento: {workProfessionalTotal}
                    </a>
                    <span> | </span>
                    <a
                      style={{ color: "#4672C5" }}
                      onClick={() => setFilterStatus("not_worked")}
                    >
                      Atrasado com lançamento: {notWorkProfessionalTotal}
                    </a>
                    <span> | </span>
                    <a
                      style={{ color: "#4672C5" }}
                      onClick={() => setFilterStatus("Pendente")}
                    >
                      Pendente de consulta: {pendingProfessionalTotal}
                    </a>
                    <span> | </span>
                    <a
                      style={{ color: "#4672C5" }}
                      onClick={() => setFilterStatus("")}
                    >
                      Limpar
                    </a>
                  </div>
                </DivButton>
              </Form>
            </StyledForm>
          </MessageWhatsappCardBody>
        </Card>
        <br />
        <div className="flex-grow-1">
          <MessageWhatsappDatatable
            reload={reload}
            setReload={setReload}
            idBatch={id}
            setNotWorkProfessionalTotal={setNotWorkProfessionalTotal}
            setWorkProfessionalTotal={setWorkProfessionalTotal}
            setPendingProfessionalTotal={setPendingProfessionalTotal}
            filterStatus={filterStatus}
          />
        </div>
      </div>
    </>
  );
};

export default BatchMessageWhatsapp;
