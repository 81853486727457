import styled from "styled-components";
import { Card, Form } from "react-bootstrap";

export const StyledForm = styled(Form)`
  flex: 1;

  @media screen and (max-width: 768px) {
    .row-3 {
      display: flex;
      gap: 24px;
      align-items: center;

      flex-direction: column;
    }

    .form-row:last-child {
      justify-content: center !important;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    .card-title {
      font-family: "Cera Pro Regular", sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 150%;
      color: #4d4c4d;
      margin-bottom: 42px;

      strong {
        font-family: "Cera Pro Medium", sans-serif;
        font-weight: 700;
        color: #4672c5;
        text-transform: capitalize;
      }
    }

    @media screen and (max-width: 768px) {
      .card-title {
        margin-bottom: 0px;
      }

      flex-direction: column;
      margin-bottom: 14px;
    }
  }

  .radio-toggle {
    display: flex;
    height: 30px;

    span {
      display: flex;
      width: fit-content;
      align-items: center;
      gap: 18px;

      font-family: "Cera Pro Thin", sans-serif;
      font-weight: 900;
      font-size: 14px;
      line-height: 150%;

      color: #807e80;
      cursor: pointer;

      margin-right: 25px;

      &:last-child {
        margin-right: 0px;
      }

      svg {
        stroke: #807e80;
      }

      &.active {
        color: #4672c5;

        svg {
          stroke: #4672c5;
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-between;
      height: 60px;

      span {
        margin-right: 8px;
        justify-content: space-between;
      }
    }
  }

  & .form-row {
    .form-group {
      margin-bottom: 37px;

      @media screen and (max-width: 480px) {
        margin-bottom: 24px;
      }
    }

    &:nth-child(3) {
      .form-group textarea {
        height: 48px;
        line-height: 150%;
      }
    }

    &:last-child {
      justify-content: space-between;
    }
  }

  .period {
    font-family: "Cera Pro Regular", sans-serif;
    font-weight: 900;
    font-size: 12px;
    line-height: 100%;
    color: #4672c5;
    margin-top: 10px;
  }
`;

export const MessageWhatsappCardBody = styled(Card.Body)`
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
    0px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 24px;

  .avatar {
    position: relative;
    display: flex;
    align-items: center;
    width: 224px;
    height: 200px;
    margin-right: 24px;
    overflow: hidden;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 20px;
  }
`;

export const Button = styled.button`
  position: relative;
  width: fit-content;
  padding: 0.875rem 1.5rem;
  font-family: "Cera Pro Bold", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  text-transform: uppercase;
  background: #fff;
  margin-right: 8px;
  border-radius: 4px;
  color: ${({ color }) => (color ? color : "#4D4C4D")};
  border: ${({ color }) =>
    color ? `1px solid ${color}` : "1px solid #4D4C4D"};

  &:disabled {
    background: #807e80;
    border-color: ${({ color }) => (color ? "#807E80" : "#4D4C4D88")};
  }

  ${({ variant }) =>
    variant === "primary" &&
    `
    background: #4672C5;
    color: white;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    margin-right: 0px;
    `};
`;

export const DivButton = styled.div`
  float: left;
`;

export const CycleData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.875rem;
  margin-left: 1rem;

  & > p {
    font-size: 1rem;
    color: black;
    margin-bottom: 0;
  }
`;
