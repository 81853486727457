import React, { useEffect, useState } from "react";
import Datatable from "../../../../../../partials/datatable/Datatable";
import {
  StyledTableCell,
  StyledTableRow
} from "../../../../../../partials/datatable/styles.js";
import useGetWindowWidth from "../../../../../../utils/useGetWindowWidth";
import moment from "moment";
import { Actions, Button, DeleteButton, Table, Td, Th, Tr } from "./styles.js";
import Swal from "sweetalert2";

const ConsultantListWhatsapp = ({ consultantList, deleteBatch }) => {
  const width = useGetWindowWidth();

  const headRows = [
    { label: "Profissional (Nome)" },
    { label: "Profissional (E-mail)" },
    { label: "Gerente de Operações   (Nome)" },
    { label: "Data de Solicitação" },
    { label: "Status" }
  ];

  const handleDeleteBatch = async id => {
    await deleteBatch(id);
  };

  const deleteBatchModal = async id => {
    return Swal.fire({
      title: "Deseja excluir este lote?",
      text: "O lote será excluído permanentemente",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar"
    }).then(async result => {
      if (result.value) {
        await handleDeleteBatch(id);
      }
    });
  };

  const handleMessageType = id => {
    switch (id) {
      case 1:
        return "Antes do fim do prazo de lançamento";
      case 2:
        return "No ultimo dia do prazo de Lançamento";
      case 3:
        return "Após o ultimo dia de prazo de lançamento";
      case 4:
        return "Exclusivo para contratos semanais";
      default:
        return "Em branco";
    }
  };

  return (
    <>
      <Table>
        <thead>
          <Tr>
            <Th>Lote</Th>
            <Th>Operador</Th>
            <Th>Cliente</Th>
            <Th>Tipo de Mensagem</Th>
            <Th>Data de Criação</Th>
            <Th>Ação</Th>
          </Tr>
        </thead>
        <tbody>
          {consultantList.map((consultant, index) => (
            <Tr key={index}>
              <Td>{consultant.idWhatsappBatch}</Td>
              <Td>{consultant.operatorName}</Td>
              <Td>{consultant.clientName}</Td>
              <Td>
                {handleMessageType(consultant.messageType.idMessageCategory)}
              </Td>
              <Td>
                {moment(consultant.creationDate).format("DD/MM/YYYY HH:mm:ss")}
              </Td>
              <Td>
                <Actions>
                  <Button
                    to={`/admin/mensagem-whatsapp/lote/${consultant.idWhatsappBatch}`}
                  >
                    Ver lote
                  </Button>
                  <DeleteButton
                    onClick={() => deleteBatchModal(consultant.idWhatsappBatch)}
                  >
                    Excluir
                  </DeleteButton>
                </Actions>
              </Td>
            </Tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ConsultantListWhatsapp;
