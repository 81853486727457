import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import queryString from "querystring";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  TextFormatOutlined
} from "@material-ui/icons";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import Loading from "../home/components/Loading";
import { bindActionCreators } from "redux";
import { StyledTextField } from "./Components/TextField/style";
import ForgotPassword from "./ForgotPassword";
import iconChecked from "../../assets/icons/icon-checked.svg";
import iconNotChecked from "../../assets/icons/icon-not-checked.svg";
import Swal from "sweetalert2";
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  Checkbox,
  Tooltip
} from "@material-ui/core";
import { decrypt, encrypt } from "../../services/Crypto";

function Login(props) {
  const { intl, history } = props;
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [forgetPassModal, setForgetPassModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });

  const [initialValues, setInitialValues] = useState({
    email: "",
    password: "",
    rememberEmailMe: false,
    rememberMe: false
  });

  const [capsLockOn, setCapsLockOn] = useState(false);

  const user = useSelector(state => state?.auth);

  useEffect(() => {
    if (!!user?.user) {
      history.push("/admin/timer");
    }
  }, [user.user, history]);

  useEffect(() => {
    setIsLoading(true);
    const stored = window.localStorage.getItem("remember:inex:rememberMe");

    if (!!stored) {
      const data = JSON.parse(stored);

      if (data) {
        setInitialValues({
          email: data.email ? data.email : "",
          password: data.key ? decrypt(data.key) : "",
          rememberEmailMe: data.rememberEmailMe,
          rememberMe: data.rememberMe
        });
      }
    }

    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleCapsLockState = event => {
      if (event.type === "keydown" || event.type === "keyup") {
        const isCapsLockOn =
          event.getModifierState && event.getModifierState("CapsLock");
        if (typeof setCapsLockOn === "function") {
          setCapsLockOn(isCapsLockOn);
        } else {
          console.warn("setCapsLockOn não está definido.");
        }
      }
    };

    window.addEventListener("keydown", handleCapsLockState);
    window.addEventListener("keyup", handleCapsLockState);

    // Remover o listener quando o componente for desmontado
    return () => {
      window.removeEventListener("keydown", handleCapsLockState);
      window.removeEventListener("keyup", handleCapsLockState);
    };
  }, []);

  const enableLoading = () => {
    setLoading(true);
    setIsLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setIsLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const isLoginExpired = () => {
    const query = queryString.parse(window.location.search.replace("?", ""));
    return query && query.hasOwnProperty("expired");
  };

  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  window.setPageTitle("Login");

  return (
    <>
      <Loading isLoading={isLoading} />
      {!isLoading && (
        <div className="kt-login__body">
          <div className="kt-login__form">
            <div className="kt-login__title">
              <h3>
                {/* https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage */}
                <FormattedMessage id="AUTH.LOGIN.TITLE" />
              </h3>
            </div>
            <Formik
              initialValues={initialValues}
              validate={values => {
                const errors = {};

                if (!values.email) {
                  // https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_FIELD"
                  });
                }

                if (!values.password) {
                  errors.password = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                enableLoading();
                login(values.email, values.password)
                  .then(authData => {
                    authData.isLogin = true;
                    if (values.rememberMe) {
                      localStorage.setItem(
                        "remember:inex:rememberMe",
                        JSON.stringify({
                          rememberMe: true,
                          rememberEmailMe: true,
                          email: values.email,
                          key: encrypt(values.password)
                        })
                      );
                    } else {
                      if (values.rememberEmailMe) {
                        localStorage.setItem(
                          "remember:inex:rememberMe",
                          JSON.stringify({
                            rememberMe: false,
                            rememberEmailMe: true,
                            email: values.email,
                            key: undefined
                          })
                        );
                      } else {
                        localStorage.setItem(
                          "remember:inex:rememberMe",
                          JSON.stringify({
                            remeberMe: false,
                            rememberEmailMe: false,
                            login: undefined,
                            key: undefined
                          })
                        );
                      }
                    }

                    disableLoading();
                    props.login(authData);
                  })
                  .catch(e => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus(
                      !e?.response && !e?.response?.data
                        ? Swal.fire(
                            "Erro!",
                            "E-mail ou senha inválidos, tente novamente mais tarde ou acione o adiministrador do sistema.",
                            "error"
                          )
                        : null
                    );
                  });
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form
                  noValidate={true}
                  autoComplete="off"
                  className="kt-form"
                  onSubmit={handleSubmit}
                >
                  {(status || isLoginExpired()) && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">
                        {status ||
                          "Sua sessão expirou, faça login para continuar"}
                      </div>
                    </div>
                  )}

                  {error && (
                    <div role="alert" className="alert alert-danger">
                      <div className="alert-text">{error}</div>
                    </div>
                  )}

                  <div className="form-group">
                    <StyledTextField
                      variant="outlined"
                      type="email"
                      label="E-mail"
                      margin="normal"
                      className="kt-width-full"
                      name="email"
                      onBlur={handleBlur}
                      onChange={e => {
                        const { value } = e.target;
                        if (value.length <= 255)
                          handleChange({
                            target: { name: "email", value: value }
                          });
                      }}
                      value={values.email}
                      helperText={touched.email && errors.email}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </div>

                  <div className="form-group">
                    <StyledTextField
                      variant="outlined"
                      type={passwordVisibility ? "text" : "password"}
                      margin="normal"
                      label="Senha"
                      className="kt-width-full"
                      name="password"
                      onBlur={handleBlur}
                      onChange={e => {
                        const { value } = e.target;
                        if (value.length <= 128)
                          handleChange({
                            target: { name: "password", value: value }
                          });
                      }}
                      value={values.password}
                      helperText={touched.password && errors.password}
                      error={Boolean(touched.password && errors.password)}
                      inputProps={{ maxLength: 255 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            {capsLockOn && (
                              <Tooltip title="CapsLock Ativo">
                                <TextFormatOutlined
                                  color="warning"
                                  style={{ cursor: "default" }}
                                />
                              </Tooltip>
                            )}
                            <IconButton
                              aria-label="Alterar visibilidade da senha"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {passwordVisibility ? (
                                <Tooltip title="Esconder senha">
                                  <VisibilityOffOutlined />
                                </Tooltip>
                              ) : (
                                <Tooltip title="Exibir senha">
                                  <VisibilityOutlined />
                                </Tooltip>
                              )}
                            </IconButton>
                          </InputAdornment>
                        )
                      }}
                    />
                  </div>

                  <div className="form-plus">
                    <FormControlLabel
                      className="remind-me"
                      control={
                        <Checkbox
                          name="rememberEmailMe"
                          checked={values.rememberEmailMe}
                          onChange={handleChange}
                          icon={
                            <img src={iconNotChecked} alt="Não selecionado" />
                          }
                          checkedIcon={
                            <img src={iconChecked} alt="Selecionado" />
                          }
                          disabled={values.rememberMe}
                        />
                      }
                      label="Lembrar e-mail"
                    />
                  </div>

                  <div className="form-plus">
                    <FormControlLabel
                      className="remind-me"
                      control={
                        <Checkbox
                          name="rememberMe"
                          checked={values.rememberMe}
                          onChange={e => {
                            const { checked } = e.target;
                            handleChange({
                              target: {
                                name: "rememberEmailMe",
                                value: checked
                              }
                            });
                            handleChange({
                              target: { name: "rememberMe", value: checked }
                            });
                          }}
                          icon={
                            <img src={iconNotChecked} alt="Não selecionado" />
                          }
                          checkedIcon={
                            <img src={iconChecked} alt="Selecionado" />
                          }
                        />
                      }
                      label="Lembrar login"
                    />
                    <div className="kt-login__actions justify-content-center forgot-password">
                      <Link
                        to="#"
                        className="kt-link kt-login__link-forgot"
                        onClick={() => setForgetPassModal(true)}
                      >
                        <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                      </Link>
                      <ForgotPassword
                        bg="white"
                        open={forgetPassModal}
                        setOpen={e => setForgetPassModal(e)}
                      />
                    </div>
                  </div>

                  <div className="kt-login__actions mb-0 pb-0 justify-content-end">
                    <button
                      id="kt_login_signin_submit"
                      type="submit"
                      disabled={isSubmitting}
                      className={`btn btn-elevate kt-login__btn-primary ${clsx({
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading
                      })}`}
                      style={loadingButtonStyle}
                    >
                      {isLoading ? "Aguarde ..." : "Entrar"}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </>
  );
}

const mapActionsToProps = dispatch =>
  bindActionCreators(
    {
      login: auth.actions.login
    },
    dispatch
  );

export default injectIntl(connect(null, mapActionsToProps)(Login));
