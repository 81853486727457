import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import {
  HiPlusCircle,
  HiMinusCircle,
  HiExclamation,
  HiClock
} from "react-icons/hi";
import { StyledAccordionHeader } from "./styles";
import { Button } from "../../pages/Approvals/components/Button/style";
import Swal from "sweetalert2";

export const CustomAccordionBody = ({
  eventKey,
  variant,
  icon,
  title,
  children,
  totalAmount,
  totalTime,
  isAllowed,
  approvedTab,
  hasTimerWithoutProposal,
  hasTimerWithoutDemand,
  hasTimerWithoutLoadedMinutes,
  handleSubmit,
  handleSubmitPending,
  handleSubmitDisapproved,
  user,
  noteForBilling,
  setProposalModal,
  log,
  operationType,
  userName,
  creationDate,
  slugName,
  nameOrActivity,
  numOfChanges,
  handleAccordionExpand,
  approvedList
}) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = async () => {
    if (!open) {
      !!approvedList &&
        approvedList.length === 0 &&
        (await handleAccordionExpand(user));
    }
    setOpen(!open);
  };

  return (
    <>
      <Accordion
        eventKey={eventKey}
        as={StyledAccordionHeader}
        variant={variant}
        onClick={handleOpen}
      >
        <div className="accordion-title">
          {icon && <span>{icon()}</span>}

          {!log ? (
            <div className="accordion-title">
              <span>{title}</span>
              <h3> {noteForBilling ? "(" + noteForBilling + ")" : ""}</h3>
            </div>
          ) : (
            <div className="accordion-title-logs">
              <span>
                {operationType} por: {userName}
              </span>{" "}
              |<span>Data: {creationDate}</span> |
              <span>
                {slugName}: {nameOrActivity}
              </span>{" "}
              |
              <span>
                {numOfChanges > 1 ? "Alterações" : "Alteração"}: {numOfChanges}
              </span>
            </div>
          )}
        </div>
        <div className="toggle-icon">
          {totalAmount && (
            <span className="total-amount">
              {new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL"
              }).format(totalAmount)}
            </span>
          )}

          {hasTimerWithoutDemand ? <HiExclamation /> : null}
          {hasTimerWithoutLoadedMinutes ? <HiClock /> : null}

          {isAllowed && (
            <div className="approval-btn">
              {!approvedTab && hasTimerWithoutProposal ? (
                <Button
                  type="button"
                  bgColor="#FB7E38"
                  onClick={() => setProposalModal(user)}
                >
                  ADD PROPOSTA
                </Button>
              ) : null}
              {!approvedTab && (
                <Button
                  type="button"
                  onClick={e => {
                    e.stopPropagation();
                    if (approvedList.length > 0) {
                      Swal.fire({
                        text: "Digite o motivo de recusa do timer:",
                        input: "textarea",
                        showCancelButton: true,
                        confirmButtonText: "Enviar",
                        cancelButtonText: "Cancelar",
                        customClass: {
                          confirmButton: "custom-confirm-button" // Classe personalizada para o botão
                        },
                        inputAttributes: {
                          "aria-label": "Digite o motivo de recusa aqui",
                          maxLength: "100"
                        },

                        onOpen: modalElement => {
                          // Substitui `didOpen` por `onOpen`
                          const textarea = Swal.getInput(); // Obtém o textarea
                          const confirmButton = modalElement.querySelector(
                            ".swal2-confirm"
                          ); // Obtém o botão "Enviar"

                          // Inicialmente desabilitar o botão "Enviar"
                          confirmButton.setAttribute("disabled", "disabled");

                          // Monitorar mudanças no textarea
                          textarea.addEventListener("input", () => {
                            if (textarea.value.trim() !== "") {
                              confirmButton.removeAttribute("disabled");
                            } else {
                              confirmButton.setAttribute(
                                "disabled",
                                "disabled"
                              );
                            }
                          });
                        },
                        preConfirm: () => {
                          const textarea = Swal.getInput();
                          if (!textarea.value.trim()) {
                            Swal.showValidationMessage(
                              "O motivo não pode estar vazio."
                            );
                            return false;
                          }
                          return textarea.value.trim();
                        }
                      }).then(result => {
                        if (result.value) {
                          const reason = result.value;
                          handleSubmitDisapproved(e, reason);
                        }
                      });
                    } else {
                      Swal.fire(
                        "Nenhum item selecionado",
                        "Selecione os item para adicionar o motivo de recusa.",
                        "warning"
                      );
                    }
                  }}
                >
                  Adicionar Motivo Recusa
                </Button>
              )}

              <Button
                type="button"
                onClick={e => {
                  if (
                    e.target.textContent === "Voltar para Pendente" &&
                    approvedList.length > 0
                  ) {
                    Swal.fire({
                      text: "Digite o motivo aqui para voltar para Pendente:",
                      input: "textarea",
                      showCancelButton: true,
                      confirmButtonText: "Enviar",
                      cancelButtonText: "Cancelar",
                      inputAttributes: {
                        maxLength: "100"
                      }
                    }).then(result => {
                      if (result.value) {
                        const reason = result.value;
                        handleSubmitPending(e, reason);
                      } else if (result.dismiss !== "cancel") {
                        handleSubmit(e);
                      }
                    });
                  } else {
                    handleSubmit(e);
                  }
                }}
              >
                {approvedTab ? "Voltar para Pendente" : "Aprovar"}
              </Button>
            </div>
          )}

          {totalTime && (
            <span className="total-amount">
              {`Total de Horas: ${totalTime}/${convertToDecimal(totalTime)}`}
            </span>
          )}
          {open ? (
            <HiMinusCircle onClick={handleOpen} />
          ) : (
            <HiPlusCircle onClick={handleOpen} />
          )}
        </div>
      </Accordion>
      <Accordion.Collapse eventKey={eventKey} in={open}>
        <>{children}</>
      </Accordion.Collapse>
    </>
  );
};

// Função para converter o tempo no formato hh:mm para decimal
function convertToDecimal(time) {
  const [hours, minutes] = time.split(":").map(Number);
  return (hours + minutes / 60).toFixed(2).replace(".", ","); // Substitui o ponto por vírgula
}
