import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { FiDownload } from "react-icons/fi";
import { MdMoreTime } from "react-icons/md";
import { InexApiService } from "../../../../../../services/InexApiService";
import CrudActions from "../../../../../../partials/datatable/CrudActions";
import Datatable from "../../../../../../partials/datatable/Datatable";
import useGetWindowWidth from "../../../../../../utils/useGetWindowWidth";
import { EditModal } from "../EditModal";
import { EditModalSimplified } from "../EditModalSimplified";
import { TimerTable } from "./TimerTable";
import { TimerTableCell, TimerTableRow } from "./style";
import { calculateDurationDiff } from "../../../../../../utils/CalculateDurationDiff";
import { transformMinutesToHours } from "../../../../../../utils/CalculateSumTime";
import { downloadFromUrl } from "../../../../../../utils/DownloadFromUrl";
import { ERoles } from "../../../../../../enums/Roles";
import { ETimerTypes } from "../../../../../../enums/TimerTypes";
import { headRowsAny, headRowsFactory } from "./utils/headRows";

export const TimerDatable = ({
  user,
  reload,
  setReload,
  setLoading,
  reloadRunningTimer,
  runningTime,
  clientOptions,
  filter,
  handleRefillTimeinfo,
  professionalsOptions,
  modalOptions,
  screenTag,
  simplified,
  manual,
  compound,
  factory,
  setSimplified,
  setManual,
  setCompound,
  setFactory,
  userProjects,
  projectFilter,
  loadReceiveTotal,
  loadTimerSum
}) => {
  const width = useGetWindowWidth();
  const api = new InexApiService();

  // Modal states
  const [editOpen, setEditOpen] = useState(null);
  const [editSimplifiedOpen, setEditSimplifiedOpen] = useState(null);
  const endpoint = "timer/paged";

  const [timers, setTimers] = useState([]);

  useEffect(() => {
    !!loadTimerSum && loadTimerSum();
  }, [timers]);

  const onRestart = async obj => {
    setLoading(true);

    const formData = new FormData();
    formData.append("IdUser", user.idUser);
    formData.append("IdClient", obj.idClient);
    formData.append("IdProject", obj.idProject);
    formData.append("ClientDemandRequester", obj.clientDemandRequester);
    formData.append("IdTimer", obj.idTimer);
    formData.append("Activity", obj.activity);
    formData.append("FullEdit", false);

    try {
      await api.makeHttpRequest({
        url: "/timer/save",
        method: "POST",
        data: formData
      });

      setReload(true);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao reiniciar o timer.",
        "error"
      );
    }

    setLoading(false);
  };

  const onStop = async (e, obj) => {
    e.stopPropagation();

    setLoading(true);

    const formData = new FormData();
    formData.append("IdUser", user.idUser);
    formData.append("IdClient", obj.idClient);
    formData.append("IdProject", obj.idProject);
    formData.append("Activity", obj.activity);
    formData.append("FullEdit", false);

    try {
      await api.makeHttpRequest({
        url: `/timer/update/${obj.idTimer}`,
        method: "PUT",
        data: formData
      });

      setReload(true);
      reloadRunningTimer();
    } catch (e) {
      if (e?.response?.status === 406) {
        setReload(true);
        reloadRunningTimer();
      }

      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao parar o timer.",
        "error"
      );
    }

    setLoading(false);
  };

  const onDelete = async id => {
    setLoading(true);

    const isNotFactory =
      user?.profile?.type !== ERoles.FACTORY
        ? `/timer/delete/${id}`
        : `/timer/factory/delete/${id}`;

    try {
      await api.makeHttpRequest({
        url: isNotFactory,
        method: "DELETE"
      });
      let tabSelected = simplified
        ? "simplified"
        : manual
        ? "manual"
        : compound
        ? "compound"
        : factory
        ? "factory"
        : "";
      setReload(true);
      loadTimerSum();
      loadReceiveTotal();
      switch (tabSelected) {
        case "simplified":
          setManual(false);
          setCompound(false);
          setFactory(false);
          setSimplified(true);
          break;
        case "manual":
          setManual(true);
          setCompound(false);
          setFactory(false);
          setSimplified(false);
          break;
        case "compound":
          setManual(false);
          setCompound(true);
          setFactory(false);
          setSimplified(false);
          break;
        case "factory":
          setManual(false);
          setCompound(false);
          setFactory(true);
          setSimplified(false);
          break;
      }
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao deletar o timer.",
        "error"
      );
    }

    setLoading(false);
  };

  const handleEditSubmit = async (e, newInfo) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("IdUser", user.idUser);
    formData.append("IdClient", newInfo.cliente);
    formData.append("IdProject", newInfo.projeto);
    formData.append("ClientDemandRequester", newInfo.solicitante);
    formData.append("Demand", newInfo.demanda ?? "");
    formData.append("Activity", newInfo.atividade);
    formData.append("startFirstTimer", newInfo.startFirstTimer);
    formData.append("endFirstTimer", newInfo.endFirstTimer);
    formData.append("IsOvertime", newInfo.horaExtra);
    formData.append("FullEdit", true);

    if (newInfo?.timerDoc?.idFile) {
      formData.append("AppFile.IdFile", newInfo.timerDoc.idFile);
    } else {
      formData.append("AppFile.File", newInfo.timerDoc);
    }

    try {
      await api.makeHttpRequest({
        url: `/timer/update/${editOpen.idTimer}`,
        method: "PUT",
        data: formData
      });

      loadTimerSum();
      loadReceiveTotal();
      setEditOpen(null);
      setEditSimplifiedOpen(null);
      setReload(true);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao editar o timer.",
        "error"
      );
    }

    setLoading(false);
  };

  const handleEditFactorySubmit = async (e, newInfo) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("IdClient", newInfo.cliente);
    formData.append("IdProject", newInfo.projeto);
    formData.append("ClientDemandRequester", newInfo.solicitante);
    formData.append("Demand", newInfo.demanda ?? "");
    formData.append("Activity", newInfo.atividade);

    formData.append("TotalHours", newInfo.totalHours);
    formData.append("IdProfessional", +newInfo.idProfessional);
    formData.append("BillingMonth", +newInfo.billingMonth);
    formData.append("IdRFQ", +newInfo.idRFQ);

    formData.append("FullEdit", true);

    if (newInfo?.timerDoc?.idFile) {
      formData.append("AppFile.IdFile", newInfo.timerDoc.idFile);
    } else {
      formData.append("AppFile.File", newInfo.timerDoc);
    }

    try {
      await api.makeHttpRequest({
        url: `timer/update/factory/${newInfo.idTimer}`,
        method: "PUT",
        data: formData
      });

      setEditOpen(null);
      setEditSimplifiedOpen(null);
      setReload(true);
      loadTimerSum();
      loadReceiveTotal();
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao editar o timer.",
        "error"
      );
    }

    setLoading(false);
  };

  const handleEditSimplifiedSubmit = async (e, newInfo) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    formData.append("IdUser", user.idUser);
    formData.append("IdClient", newInfo.cliente);
    formData.append("IdProject", newInfo.projeto);
    formData.append("ClientDemandRequester", newInfo.solicitante);
    formData.append("Demand", newInfo.demanda ?? "");
    formData.append("Activity", newInfo.atividade);
    formData.append("startFirstTimer", newInfo.startFirstTimer);
    formData.append("endFirstTimer", newInfo.endFirstTimer);
    formData.append("IsOvertime", newInfo.horaExtra);
    formData.append("FullEdit", true);
    formData.append("TotalHours", newInfo.totalHours);

    if (newInfo?.timerDoc?.idFile) {
      formData.append("AppFile.IdFile", newInfo.timerDoc.idFile);
    } else {
      formData.append("AppFile.File", newInfo.timerDoc);
    }

    try {
      await api.makeHttpRequest({
        url: `/timer/update/simplified/${editSimplifiedOpen.idTimer}`,
        method: "PUT",
        data: formData
      });

      loadTimerSum();
      loadReceiveTotal();
      setEditSimplifiedOpen(null);
      setReload(true);
    } catch (e) {
      Swal.fire(
        "Erro!",
        e?.response?.data ? e.response.data : "Erro ao editar o timer.",
        "error"
      );
    }

    setLoading(false);
  };

  const formatRow = r => {
    const crudActions = [];

    if (r.endFirstTimer) {
      if (
        r?.timerStatus?.idTimerStatus !== 2 ||
        r?.idTimerType == ETimerTypes.SIMPLIFIED
      ) {
        crudActions.push(...["edit-form", "delete"]);
      }
      // else {
      //   crudActions.push(...["play"]);
      // }
    }
    // else {
    //   crudActions.push(...["stop"]);
    // }

    let statusColor = "";
    if (
      user?.profile?.type !== ERoles.FACTORY &&
      r.timerStatus.idTimerStatus === 2
    ) {
      statusColor = "#61AA64";
    } else if (
      user?.profile?.type !== ERoles.FACTORY &&
      r.timerStatus.idTimerStatus === 5
    ) {
      statusColor = "transparent";
    } else if (
      r.disapprovedReason !== null &&
      r.disapprovedReason !== "" &&
      r.disapprovedReason !== undefined
    ) {
      statusColor = "#FA8447";
    } else {
      statusColor = "#FAD247";
    }

    const handleDisapprovedReason = reason => {
      Swal.fire("Motivo da Recusa do Timer!", reason, "info");
    };

    return (
      <>
        {user?.profile?.type === ERoles.FACTORY ? (
          <TimerTable
            r={r}
            onConfirmDelete={onDelete}
            handleEditSubmit={handleEditFactorySubmit}
            clientOptions={clientOptions}
            professionalsOptions={professionalsOptions}
            modalOptions={modalOptions}
            user={user}
          />
        ) : (
          <TimerTableRow
            hover
            pointer={screenTag ? "pointer" : ""}
            tabIndex={-1}
            key={"timer_" + r.idTimer}
            onClick={() => (screenTag ? handleRefillTimeinfo(r) : null)}
          >
            <TimerTableCell scope="row" align="center">
              <span
                className="status-color"
                style={{ background: statusColor }}
              />
              <CrudActions
                actions={crudActions}
                module="timer"
                handleEdit={
                  [1, 4].includes(r.idTimerType)
                    ? setEditOpen
                    : setEditSimplifiedOpen
                }
                onConfirmPlay={onRestart}
                onConfirmDelete={onDelete}
                onConfirmStop={onStop}
                id={r.idTimer}
                obj={r}
              />
            </TimerTableCell>
            <TimerTableCell scope="row">
              {moment(r.startFirstTimer).format("DD/MM/YYYY")}
            </TimerTableCell>
            <TimerTableCell scope="row">{r.activity}</TimerTableCell>
            <TimerTableCell scope="row">{r.demand ?? "--"}</TimerTableCell>
            <TimerTableCell scope="row">{r.project.name}</TimerTableCell>
            <TimerTableCell scope="row">{r.client.name}</TimerTableCell>
            <TimerTableCell scope="row" align="center">
              <div>
                {moment(r.startFirstTimer).format("HH:mm")}{" "}
                <span className="hyphen" />{" "}
                {r.endFirstTimer
                  ? moment(r.endFirstTimer).format("HH:mm")
                  : "--:--"}
              </div>
            </TimerTableCell>
            <TimerTableCell scope="row" align="center">
              <span className="time-total">
                {r.endFirstTimer
                  ? transformMinutesToHours(r.durationMinutes)
                  : runningTime}
              </span>
            </TimerTableCell>
            <TimerTableCell scope="row" align="center">
              {r.isOvertime ? <MdMoreTime size={20} color="#4672C5" /> : "--"}
            </TimerTableCell>
            <TimerTableCell scope="row" align="center">
              {r.appFile && r.appFile.tempUri ? (
                <span
                  className="cursor-pointer"
                  onClick={() =>
                    downloadFromUrl(r.appFile.tempUri, r.appFile.name)
                  }
                  title={"Baixar: " + r.appFile.name}
                >
                  <FiDownload size={20} color="#4672C5" />
                </span>
              ) : (
                "--"
              )}
            </TimerTableCell>
            <TimerTableCell scope="row">
              {r.timerStatus.idTimerStatus === 2
                ? r.approver
                  ? r.approver.name
                  : "--"
                : "--"}
            </TimerTableCell>
          </TimerTableRow>
        )}
      </>
    );
  };

  const handleGetHeadRows = profile => {
    if (profile === ERoles.FACTORY) {
      return headRowsFactory;
    }

    if (profile !== ERoles.FACTORY) {
      return headRowsAny;
    }
  };

  return (
    <>
      {/* Search atribute send current user id and the period */}
      <Datatable
        search={user.idUser}
        dateRange={filter}
        endpoint={endpoint}
        className="h-100 m-0 p-0"
        headRows={handleGetHeadRows(user?.profile?.type)}
        styledTableCell={TimerTableCell}
        styledTableRow={TimerTableRow}
        formatRow={formatRow}
        rows={timers}
        isTimerDatatable
        setRows={setTimers}
        reload={reload}
        setReload={setReload}
        width={width}
        hiddenReload
        projectTimerFilter={projectFilter}
      />

      <EditModal
        user={user}
        open={editOpen}
        setOpen={setEditOpen}
        clientOptions={clientOptions}
        handleEditSubmit={handleEditSubmit}
      />

      <EditModalSimplified
        user={user}
        open={editSimplifiedOpen}
        setOpen={setEditSimplifiedOpen}
        clientOptions={clientOptions}
        handleEditSubmit={handleEditSimplifiedSubmit}
        reload={reload}
      />
    </>
  );
};
