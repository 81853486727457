import React, { useState, useEffect } from "react";
import { Form, Col } from "react-bootstrap";
import { InputField } from "../../../../components/InputField";
import FormatJiraHour from "../../../../../../utils/FormatJiraHour";
import "react-datepicker/dist/react-datepicker.css";
import IsValidTotalHours from "../../../../../../utils/validators/IsValidTotalHours";

export const TimerSimplified = ({ handleSetHours, reload }) => {
  const [totalHours, setTotalHours] = useState("");

  useEffect(() => {
    if (totalHours) {
      handleSetHours(totalHours);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalHours]);

  useEffect(() => {
    if (reload) {
      setTotalHours("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <>
      <Form.Group as={Col} lg="2" xs="12">
        <InputField
          type="text"
          name="totalTimer"
          label="Horas Totais"
          placeholder="Ex.: 160h20m"
          value={FormatJiraHour(totalHours)}
          onChange={e => setTotalHours(FormatJiraHour(e.target.value))}
          maxLength={7}
          isInvalid={totalHours && !IsValidTotalHours(totalHours)}
          required
        />
      </Form.Group>
    </>
  );
};
