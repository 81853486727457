import React, { useEffect, useState } from "react";
import Loading from "../../../home/components/Loading";
import { AdminTitle } from "../../components/AdminTitle";
import { WhatsappIcon } from "../../../../components/IconsSVG";
import { Link } from "react-router-dom";
import { Card, Form, Col, Tabs, Tab } from "react-bootstrap";
import {
  StyledForm,
  MessageWhatsappCardBody,
  Button,
  DivButton
} from "./styles";
import { InexApiService } from "../../../../services/InexApiService";
import store from "../../../../store/store";
import moment from "moment";
import Swal from "sweetalert2";
import ConsultantListWhatsapp from "./components/ConsultantListWhatsapp";

const MessageWhatsapp = () => {
  const { auth } = store.getState();
  const api = new InexApiService();
  const [loading, setLoading] = useState(false);
  const [operationUser, setOperationUser] = useState();
  const [operationUsers, setOperationUsers] = useState([]);
  const [consultantList, setConsultantList] = useState([]);

  const loadOperationUsers = async () => {
    const operations = await api.makeHttpRequest({
      url: "/user/operations"
    });

    const operationUsers =
      operations.length > 0
        ? operations.map(item => ({
            label: item.name,
            value: item.idUser,
            isDisabled: false
          }))
        : [];

    setOperationUsers(operationUsers);
    let user = auth.user;
    operationUsers.forEach(operation => {
      if (operation.value === user.idUser) {
        setOperationUser(user.idUser);
      }
    });
  };

  useEffect(() => {
    loadOperationUsers();
    loadConsultantList();
  }, []);

  const loadConsultantList = async () => {
    setLoading(true);
    const list = await api.makeHttpRequest({
      url: "/whatsappmessage/batch"
    });

    !!list ? setConsultantList(list) : setConsultantList([]);
    setLoading(false);
  };

  const handleDeleteBatchById = async id => {
    try {
      setLoading(true);
      const response = await api.makeHttpRequest({
        url: `/whatsappmessage/batch/${id}/delete`,
        method: "POST"
      });
      setLoading(false);
      if (!!response) {
        Swal.fire({
          title: "Sucesso!",
          text: "Lote excluido com sucesso",
          icon: "success",
          confirmButtonText: "Ok"
        }).then(async () => {
          await loadConsultantList();
        });
      }
    } catch (e) {
      setLoading(false);
      Swal.fire(
        "Erro!",
        e?.response?.data
          ? e.response.data
          : "Erro ao excluir lote selecionado!",
        "error"
      );
    }
  };

  return (
    <>
      <Loading isLoading={loading} />
      <div className="d-flex flex-column h-100 pl-3 pr-3">
        <AdminTitle title="WhatsApp" icon={WhatsappIcon} disableHeader />
        <Card className="mt-4">
          <MessageWhatsappCardBody>
            <StyledForm>
              <Form className="form-admin">
                <DivButton style={{ width: "100%" }}>
                  <Link to={"/admin/mensagem-whatsapp/adicionar"}>
                    {" "}
                    <Button
                      variant="primary"
                      type="button"
                      color="#4672C5"
                      title={"Salvar"}
                    >
                      <span className="text-light">Adicionar lote</span>
                    </Button>
                  </Link>
                </DivButton>
              </Form>
            </StyledForm>
          </MessageWhatsappCardBody>
        </Card>
        <br />
        <div className="flex-grow-1">
          <ConsultantListWhatsapp
            deleteBatch={id => handleDeleteBatchById(id)}
            consultantList={consultantList}
          />
        </div>
      </div>
    </>
  );
};

export default MessageWhatsapp;
